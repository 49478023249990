import React from "react";
import { Link as ScrollLink } from "react-scroll";

const Footer = () => {
  // Get the full date and time
  const currentDate = new Date().toLocaleString();

  return (
    <footer className="bg-brown-700 text-white py-4">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-4 md:px-0">
        {/* Left Side: Name, Email, Date */}
        <div className="flex flex-col md:flex-row items-center mb-4 md:mb-0">
          <div className="mr-4 ml-4">
            <span className="font-bold">Gravel Geo-technical Works</span>
          </div>
          {/* <div className="mr-4">
            <span>Email: Telsemplc406@gmail.com</span>
          </div> */}
          <div>
            <span>&copy; {currentDate}</span>
          </div>
        </div>

        {/* Right Side: Navigation Bar */}
        <nav className="flex flex-row  items-center ">
          <ScrollLink
            to="navbar"
            smooth={true}
            duration={500}
            className="text-white hover:text-gray-400 px-2 md:px-4 cursor-pointer"
          >
            Home
          </ScrollLink>
          <ScrollLink
            to="projects"
            smooth={true}
            duration={500}
            className="text-white hover:text-gray-400 px-2 md:px-4 cursor-pointer"
          >
            Projects
          </ScrollLink>
          <ScrollLink
            to="services"
            smooth={true}
            duration={500}
            className="text-white hover:text-gray-400 px-2 md:px-4 cursor-pointer"
          >
            Services
          </ScrollLink>
          <ScrollLink
            to="testimonial"
            smooth={true}
            duration={500}
            className="text-white hover:text-gray-400 px-2 md:px-4 cursor-pointer"
          >
            Testimonials
          </ScrollLink>
          <ScrollLink
            to="Contact Us"
            smooth={true}
            duration={500}
            className="text-white hover:text-gray-400 px-2 md:px-4 cursor-pointer"
          >
            Contact Form
          </ScrollLink>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
