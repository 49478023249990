import React from "react";

const AboutUs = () => {
  return (
    <div id="AboutUs" className="about-us bg-brown-50 py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-brown-800 mb-8">About Us</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="md:col-start-1">
            <div className="rounded-md bg-white bg-opacity-50 p-6">
              <h3 className="text-xl font-semibold text-brown-800 mb-4">
                {" "}
               Our Main Activties Include:
              </h3>
              <p className="text-brown-700 text-center ">
                DCP
                <br />
                BOREHOLE DRILLING
                <br />
                TEST PIT
                <br />HAMMER TEST
              </p>
            </div>
            <div className="rounded-md bg-white bg-opacity-50 p-6 mt-6">
              <h3 className="text-xl font-semibold text-brown-800 mb-4">
                Our Mission
              </h3>
              <p className="text-brown-700">
                Committed to customer satisfaction by providing state of the art
                Investigation service that is efficient, affordable,
                aesthetically appealing, functionally comprehensive, durable and
                most of all which give great attention to the peoples who use
                them.
              </p>
            </div>
          </div>
          <div>
            <div className="rounded-md bg-white bg-opacity-50 p-6">
              <h3 className="text-xl font-semibold text-brown-800 mb-4">
                Our Vision
              </h3>
              <p className="text-brown-700">
              Take the Geo-Technical Investigation work one step closer to perfection by giving a word class Investigation service. 
              </p>
            </div>

            <div className="rounded-md bg-white bg-opacity-50 p-6 mt-6">
              <h3 className="text-xl font-semibold text-brown-800 mb-4">
                Our Moto
              </h3>
              <p className="text-brown-700">
              Grounded in Excellence: Drilling Deep, Testing Strong
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
