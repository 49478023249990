import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SlidingImagesComponent = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true, // Enable sliding when touched
  };

  return (
    <div className="sliding-images-container mt-10 ">
      <h1 className=" text-4xl font-serif bg-brown-500  p-4 text-white font-semibold rounded">Projects</h1>
      <section className="section-heading">
        <h2 className="text-2xl font-bold mb-4 mt-4 font-serif bg-brown-200 inline-block px-7">
          B+G+13 Mixed Use Building
        </h2>
        <div className=" px-8 lg:px-40 place-self-center">
          <Slider {...settings}>
            <div>
              <img
                src="./Images/bg (1).jpg"
                alt="Image 1"
                className="sliding-image "
              />
            </div>
            <div className=" ml-2 mr-2">
              <img
                src="./Images/bg (2).jpg"
                alt="Image 2"
                className="sliding-image"
              />
            </div>
            <div className="ml-2 mr-2">
              <img
                src="./Images/bg (3).jpg"
                alt="Image 3"
                className="sliding-image"
              />
            </div>
            <div className="ml-2 mr-2">
              <img
                src="./Images/bg (4).jpg"
                alt="Image 3"
                className="sliding-image"
              />
            </div>
            <div className="ml-2 mr-2">
              <img
                src="./Images/bg (5).jpg"
                alt="Image 3"
                className="sliding-image"
              />
            </div>
            <div className="ml-2 mr-2">
              <img
                src="./Images/bg (6).jpg"
                alt="Image 3"
                className="sliding-image"
              />
            </div>

            {/* Add more images as needed */}
          </Slider>
        </div>
        <p className="mt-8 text-center font-serif font-bold">
          <span className="text-brown-500">Two bore hole 10 meter</span>, 
          <br/><span className="text-brown-500">Location</span>:- Addis ababa around Nefase sleke sub city jemo
          <br/><span className="text-brown-500">Area Owner</span>:-  Adenete Asefa
        </p>
      </section>

      <section className="section-heading">
      <h2 className="text-2xl font-bold mb-4 mt-4 font-serif bg-brown-200 inline-block px-7">
         Mosa Real State B+G-10
        </h2>
        <div className=" px-8 lg:px-40 place-self-center">
          <Slider {...settings}>
            <div>
              <img
                src="./Images/Case2 (1).jpg"
                alt="Image 1"
                className="sliding-image "
              />
            </div>
            <div className=" ml-2 mr-2">
              <img
                src="./Images/Case2 (2).jpg"
                alt="Image 2"
                className="sliding-image"
              />
            </div>
            <div className="ml-2 mr-2">
              <img
                src="./Images/Case2 (3).jpg"
                alt="Image 3"
                className="sliding-image"
              />
            </div>
            <div className="ml-2 mr-2">
              <img
                src="./Images/Case2 (4).jpg"
                alt="Image 3"
                className="sliding-image"
              />
            </div>
            <div className="ml-2 mr-2">
              <img
                src="./Images/Case2 (5).jpg"
                alt="Image 3"
                className="sliding-image"
              />
            </div>
            <div className="ml-2 mr-2">
              <img
                src="./Images/Case2 (6).jpg"
                alt="Image 3"
                className="sliding-image"
              />
            </div>

            {/* Add more images as needed */}
          </Slider>
        </div>
        <p className="mt-8 text-center font-serif font-bold">
          <span className="text-brown-500">Two bore hole 12 meter</span>, 
          <br/><span className="text-brown-500">Location</span>:- Addis ababa, Kera
          <br/><span className="text-brown-500">Area Owner</span>:-  Mosa Real State
        </p>
      </section>
      <section className="section-heading">
      <h2 className="text-2xl font-bold mb-4 mt-4 font-serif bg-brown-200 inline-block px-7">
         Safaricom Telecommunication Ethiopia PLC
        </h2>
        <div className=" px-8 lg:px-40 place-self-center">
          <Slider {...settings}>
            <div>
              <img
                src="./Images/aleta.jpg"
                alt="Image 1"
                className="sliding-image "
              />
            </div>
            <div className=" ml-2 mr-2">
              <img
                src="./Images/aleta2.jpg"
                alt="Image 2"
                className="sliding-image"
              />
            </div>
            <div className="ml-2 mr-2">
              <img
                src="./Images/aleta4.jpg"
                alt="Image 3"
                className="sliding-image"
              />
            </div>
            <div className="ml-2 mr-2">
              <img
                src="./Images/aleta3.jpg"
                alt="Image 3"
                className="sliding-image"
              />
            </div>
            <div className="ml-2 mr-2">
              <img
                src="./Images/Safaricom (5).jpg"
                alt="Image 3"
                className="sliding-image"
              />
            </div>
            <div className="ml-2 mr-2">
              <img
                src="./Images/Safaricom (6).jpg"
                alt="Image 3"
                className="sliding-image"
              />
            </div>

            {/* Add more images as needed */}
          </Slider>
        </div>
        <p className="mt-8 text-center font-serif font-bold">
          <span className="text-brown-500">3 Meter DCP Test</span>, 
          <br/><span className="text-brown-500">Location</span>:- Aleta Wondo
          <br/><span className="text-brown-500">Site Id</span>:-  704049
        </p>
      </section>
      



      <section className="section-heading">
      <h2 className="text-2xl font-bold mb-4 mt-4 font-serif bg-brown-200 inline-block px-7">
         Safaricom Telecommunication Ethiopia PLC
        </h2>
        <div className=" px-8 lg:px-40 place-self-center">
          <Slider {...settings}>
            <div>
              <img
                src="./Images/Safaricom (1).jpg"
                alt="Image 1"
                className="sliding-image "
              />
            </div>
            <div className=" ml-2 mr-2">
              <img
                src="./Images/Safaricom (2).jpg"
                alt="Image 2"
                className="sliding-image"
              />
            </div>
            <div className="ml-2 mr-2">
              <img
                src="./Images/Safaricom (3).jpg"
                alt="Image 3"
                className="sliding-image"
              />
            </div>
            <div className="ml-2 mr-2">
              <img
                src="./Images/Safaricom (4).jpg"
                alt="Image 3"
                className="sliding-image"
              />
            </div>
            <div className="ml-2 mr-2">
              <img
                src="./Images/Safaricom (5).jpg"
                alt="Image 3"
                className="sliding-image"
              />
            </div>
            <div className="ml-2 mr-2">
              <img
                src="./Images/Safaricom (6).jpg"
                alt="Image 3"
                className="sliding-image"
              />
            </div>

            {/* Add more images as needed */}
          </Slider>
        </div>
        <p className="mt-8 text-center font-serif font-bold">
          <span className="text-brown-500">3 Meter DCP Test</span>, 
          <br/><span className="text-brown-500">Location</span>:- Gammo
          <br/><span className="text-brown-500">Site Id</span>:-  704049
        </p>
      </section>


 {/* add sections */}





      
    </div>
  );
}

export default SlidingImagesComponent;
